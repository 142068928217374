import * as actionTypes from './actionTypes/otherActionTypes'
import * as authActionTypes from './actionTypes/authActionTypes'
import produce from "immer";

const initialState = {
    allJobData: {
        data: [],
        jobs_complete: 0,
        jobs_in_progress: 0,
        number_of_jobs: 0,
        isDone: false
    },
    singleJobData:null,
    jobProductData: null,
    isLoading: false,
    isTableLoading: false,
    error: null
};

const dashboardData = produce(
    (state = initialState, action) => {
        const { type, payload } = action;

        switch (type) {

            case actionTypes.GET_ALL_JOB_DATA_REQUEST: {
                if(!state.allJobData.isDone) {
                    state.isLoading = true;
                }
                state.error = null;
                return state;
            }
            case actionTypes.GET_SINGLE_JOB_DATA_REQUEST: {
                state.isLoading = true;
                state.error = null;
                return state;
            }
            case actionTypes.GET_JOB_PRODUCT_DATA_REQUEST: {
                state.isTableLoading = true;
                state.error = null;
                return state;
            }
            case actionTypes.SUBMIT_JOB_FILE_REQUEST: {
                state.isLoading = true;
                state.error = null;
                return state;
            }
            case authActionTypes.USER_LOGIN_REQUEST: {
                state.isLoading = true;
                state.error = null;
                return state;
            }
            case authActionTypes.USER_LOGOUT_REQUEST: {
                state.isLoading = true;
                state.error = null;
                return state;
            }

            case actionTypes.GET_ALL_JOB_DATA_SUCCEED: {
                if(payload) {
                    state.allJobData.data = payload.data ? payload.data.reverse() : [];
                    state.allJobData.jobs_complete = parseInt(payload.jobs_complete) ? payload.jobs_complete : 0;
                    state.allJobData.jobs_in_progress = parseInt(payload.jobs_in_progress) ? payload.jobs_in_progress : 0;
                    state.allJobData.number_of_jobs = parseInt(payload.number_of_jobs) ? payload.number_of_jobs : 0;
                }
                if(!state.allJobData.isDone) {
                    state.allJobData.isDone = true;
                }
                state.isLoading = false;
                state.error = null;
                return state;
            }
            case actionTypes.GET_SINGLE_JOB_DATA_SUCCEED: {
                state.singleJobData = payload.data;
                state.isLoading = false;
                state.error = null;
                return state;
            }
            case actionTypes.GET_JOB_PRODUCT_DATA_SUCCEED: {
                state.jobProductData = payload.data;
                state.isTableLoading = false;
                state.error = null;
                return state;
            }
            case actionTypes.SUBMIT_JOB_FILE_SUCCEED: {
                state.error = null;
                return state;
            }
            case authActionTypes.USER_LOGIN_SUCCEED: {
                state.isLoading = false;
                state.error = null;
                return state;
            }
            case authActionTypes.USER_LOGOUT_SUCCEED: {
                state.isLoading = false;
                state.error = null;
                return state;
            }

            case actionTypes.GET_ALL_JOB_DATA_FAILED: {
                state.isLoading = false;
                state.error = payload;
                return state;
            }
            case actionTypes.GET_SINGLE_JOB_DATA_FAILED: {
                state.isLoading = false;
                state.error = payload;
                return state;
            }
            case actionTypes.GET_JOB_PRODUCT_DATA_FAILED: {
                state.isTableLoading = false;
                state.error = payload;
                return state;
            }
            case actionTypes.SUBMIT_JOB_FILE_FAILED: {
                state.isLoading = false;
                state.error = payload;
                return state;
            }
            case authActionTypes.USER_LOGIN_FAILED: {
                state.isLoading = false;
                state.error = payload;
                return state;
            }

            default: {
                return state;
            }
        }
    }
);

export default dashboardData;
