import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {userLogin} from "../redux/actions/authActions";


const Login=()=>{

    const dispatch = useDispatch();

    const [formState, setFormState] = useState({
        username: '',
        password: '',
        remember: false
    })

    const onChangeHandler = (e) => {
        const {name, value, type} = e.target;
        setFormState({
            ...formState,
            [name]: type === 'checkbox' ? !formState.remember : value
        })
    }

    const onFormSubmit=(e)=>{
        e.preventDefault();

        dispatch(userLogin(formState))
    }

    return(
        <div className="login-container row">
            <div className='login-img col-12 col-md-6'>
                <div style={{position: 'relative'}}>
                    <img style={{width: '100%', objectFit: 'cover', objectPosition: '0 0', height: '100%', top: 100}}
                         src={`/images/login_bg.png`} alt='Background'/>
                </div>
            </div>

            <div className='col-md-6 login-right-container'>
                <div className='d-flex align-items-center justify-content-center' style={{height: '100%', width: '100%'}}>
                    <div style={{width: '100%'}} className='login-form-container'>
                        <div className='d-flex brand-container'>
                            <div>Commerce</div>
                            <div>Flow.ai</div>
                        </div>
                        <div className='mb-4'>Log In to your account</div>

                        <form onSubmit={onFormSubmit}>
                            <div className="mb-4 text-start">
                                <input
                                    name="username"
                                    className="form-control"
                                    placeholder="Email Address"
                                    type="email"
                                    required
                                    value={formState.username}
                                    onChange={onChangeHandler}
                                />
                            </div>
                            <div className="mb-4 text-start">
                                <input
                                    name="password"
                                    className="form-control"
                                    placeholder="Password"
                                    type="password"
                                    required
                                    value={formState.password}
                                    onChange={onChangeHandler}
                                />
                            </div>

                            <div className='d-flex justify-content-between mb-4'>
                                <div className="form-check text-start">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="remember"
                                        name='remember'
                                        checked={formState.remember}
                                        onChange={onChangeHandler}
                                    />
                                    <label className="form-check-label" htmlFor="remember">Keep me logged in</label>
                                </div>
                                <div className='text-end forgot-pw'>Forgot Password?</div>
                            </div>

                            <button className="button-custom btn" type="submit">Sign In</button>

                        </form>

                        <div className='d-flex justify-content-center sign-up-container'>
                            <div className='color-primary normal-font'>Don’t have an account?</div>
                            <div className='color-secondary link-custom fw-bold normal-font ms-1'>Sign Up</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;