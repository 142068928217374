import axios from "axios";

const baseURL = process.env.REACT_APP_API_ENDPOINT;

const request = async (config) => {
    let defaultHeaders = {
        // "Access-Control-Allow-Origin": "*",
    }

    const {auth, data, url, method, responseType, headers} = config || {};

    defaultHeaders = {
        ...defaultHeaders,
        ...headers
    }

    if (auth) {
        // const token = await localStorage.getItem('token');
        //
        // defaultHeaders = {
        //     ...defaultHeaders,
        //     ...headers,
        //     'Authorization': `Bearer ${token}`
        // }

    }

    return new Promise((resolve, reject) => {
        axios({
            method: method,
            url: `${baseURL}${url}`,
            headers: defaultHeaders,
            data: data,
            responseType: responseType ? responseType : null
        })
            .then((data) => {
                if(responseType === 'blob'){
                    resolve(data)
                } else {
                    if(data?.data?.status === "KO" && (!url.includes("job") && method !== 'POST')){
                        reject(data.data)
                    } else {
                        resolve(data.data);
                    }
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 401 && !url.includes('login')) {
                    localStorage.removeItem('token');
                }
                reject(error.response)
            })
    })

}

export default request;