import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate, useParams} from "react-router-dom";
import moment from "moment";
import {saveAs} from 'file-saver';

import Header from "../components/Header";
import JobDataTable from "../components/JobDataTable";
import {getJobProductDetails, getSingleJobDetails} from "../redux/actions/otherActions";
import capitalizeFirstLetter from "../helpers/capitalizeHelper";
import request from "../helpers/requestHelper";
import {Breadcrumb} from "react-bootstrap";

const JobResult = () => {

    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const singleJobDetails = useSelector((el) => el.singleJobData)
    const jobDetails = useSelector((el) => el.allJobData)
    const jobProductData = useSelector((el) => el.jobProductData)
    const errorData = useSelector((el) => el.error)
    const isLoading = useSelector((el) => el.isTableLoading)

    const [jobState, setJobState] = useState({
        id: "",
        date: "",
        noOfRecords: null,
        status: "",
    })

    useEffect(() => {
        if (jobDetails.data && jobDetails.data.some((el) => el.jobid === params.jobId)) {
            let job = jobDetails.data.find((el) => el.jobid === params.jobId)
            setJobState({
                id: job.jobid,
                date: job.submit_date,
                noOfRecords: job.product_count,
                status: job.status,
            })
        }
        dispatch(getSingleJobDetails(params.jobId));
    }, [])

    useEffect(() => {
        if (errorData && (errorData.code === 404 || errorData?.message.includes("Sorry"))) {
            navigate(`/`)
        }
    }, [errorData])

    const getJobProductData = (id) => {
        dispatch(getJobProductDetails(id))
    }

    const onDownloadFiles = async (id, type) => {
        const res = await request({
            url: `/compilations/download?jobid=${id}&file_type=${type}`,
            auth: true,
            method: 'GET',
            responseType: 'arraybuffer'
        });
        let name = `${id}.${type}`
        const blob = new Blob([res], {
            type: `application/${type}`,
        });
        saveAs(blob, name)
    }

    return (
        <div className='mb-4'>
            <Header header={"Job Results"}/>
            <div className='container container-new'>

                <Breadcrumb className='mt-4'>
                    <Breadcrumb.Item className='normal-font breadcrumb-custom'><Link className='color-secondary'
                                                                                     to={'/'}>Home</Link></Breadcrumb.Item>
                    <Breadcrumb.Item className='normal-font' active>{jobState.id}</Breadcrumb.Item>
                </Breadcrumb>

                <div
                    className='round-container pt-4 pb-4 ps-5 pe-5 m-0 mt-4 d-block d-md-flex justify-content-between job-detail-container'>
                    <div>
                        <div>Job ID:</div>
                        <div>{jobState.id}</div>
                    </div>

                    <div>
                        <div>Date Submitted:</div>
                        <div>{moment(jobState.date).format("DD/MM/YYYY")}</div>
                    </div>

                    <div>
                        <div>No of Records:</div>
                        <div>{jobState.noOfRecords}</div>
                    </div>

                    <div>
                        <div>Status:</div>
                        <div>{capitalizeFirstLetter(jobState.status)}</div>
                    </div>

                    {(jobState.status === "completed") &&
                        <div className='download-column'>
                            <div className='d-flex justify-content-center'>
                                <div onClick={() => onDownloadFiles(jobState.id, 'csv')} className='color-secondary me-3'>CSV</div>
                                <div onClick={() => onDownloadFiles(jobState.id, 'json')} className='color-secondary me-3'>JSON</div>
                                <div onClick={() => onDownloadFiles(jobState.id, 'xls')} className='color-secondary'>XLS</div>
                            </div>
                        </div>
                    }
                </div>

                <div className='mt-4'>
                    <JobDataTable
                        data={singleJobDetails && singleJobDetails.products ? singleJobDetails.products : []}
                        getJobProductData={getJobProductData}
                        jobProductData={jobProductData}
                        isLoading={isLoading}
                    />
                </div>

            </div>
        </div>
    )
}

export default JobResult;