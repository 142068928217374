import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {PaginationProvider} from 'react-bootstrap-table2-paginator';
import React, {useState} from "react";
import capitalizeFirstLetter from "../helpers/capitalizeHelper";
import {Spinner} from "reactstrap";
import SortIcon from "../assets/sort.png";
import moment from "moment";

const SIZE_PER_PAGE = 10;

const columns = [{
    dataField: 'gtin',
    text: 'GTIN',
    sort: true,
    sortCaret: () => {
        return (
            <span>
                    <img src={SortIcon} style={{width:16, marginLeft:5, height:12, fill:"gray"}}/>
                </span>
        );
    }
}, {
    dataField: 'status',
    text: 'Status',
    sort: true,
    sortCaret: () => {
        return (
            <span>
                    <img src={SortIcon} style={{width:16, marginLeft:5, height:12, fill:"gray"}}/>
                </span>
        );
    },
    formatter: (rowContent, row) => {
        return capitalizeFirstLetter(row.status)
    }
}, {
    dataField: 'number_of_input_images',
    text: 'No of Images',
    sort: true,
    sortCaret: () => {
        return (
            <span>
                    <img src={SortIcon} style={{width:16, marginLeft:5, height:12, fill:"gray"}}/>
                </span>
        );
    }
}, {
    dataField: 'number_of_error_images',
    text: 'No of Error Images',
    sort: true,
    sortCaret: () => {
        return (
            <span>
                    <img src={SortIcon} style={{width:16, marginLeft:5, height:12, fill:"gray"}}/>
                </span>
        );
    }
}];

const JobDataTable = (props) => {

    const options = {
        custom: true,
        totalSize: props.data.length,
        sizePerPage: SIZE_PER_PAGE
    };

    const [selected, setSelected] = useState([]);
    const [expanded, setExpanded] = useState([]);

    const handleNextPage = ({page, onPageChange}) => () => {
        onPageChange(page + 1);
    }

    const handlePrevPage = ({page, onPageChange}) => () => {
        onPageChange(page - 1);
    }

    const handleOnSelectAll = (isSelect, rows) => {
        const ids = rows.map(r => r.id);
        if (isSelect) {
            setSelected(ids)
        } else {
            setSelected([]);
        }
    }

    const handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            setSelected([...selected, row.id])
        } else {
            setSelected(selected.filter(x => x !== row.id))
        }
    }

    const handleOnExpand = (row, isExpand) => {
        if (isExpand) {
            props.getJobProductData(row.pid)
            setExpanded([row.pid])
        } else {
            setExpanded([])
        }
    }

    const expandRow = {
        onlyOneExpanding: true,
        expanded: expanded,
        onExpand: handleOnExpand,
        renderer: row => (
            <div className='expanded-area'>
                <div className='d-flex justify-content-between'>
                    <div className='normal-font fw-bold'>Product Details</div>
                    <div onClick={() => handleOnExpand(row, false,)} style={{cursor: "pointer"}}
                         className='color-secondary'>Close
                    </div>
                </div>
                {props.isLoading ?
                    <Spinner style={{color:"#79BEF1"}}  animation='border'>
                        {' '}
                    </Spinner>
                    :
                    <div className='value-area mt-3'>
                        <div className='data-row row' style={{borderBottom:"1px solid #79BEF1", paddingBottom:"5px"}}>
                            <div className='text-start fw-bold'>Candidate</div>
                            <div className='text-start fw-bold'>Value</div>
                        </div>
                        <div className='scrollable-area'>
                            {props.jobProductData && Object.entries(props.jobProductData).map(([key, value]) => {
                                if (value) {
                                    if(key !== 'pid' && key !== 'input_images' && key !== 'jobid' && key !== 'error_urls' && key !== 'product_id') {
                                        if (Array.isArray(value)) {
                                            let data = value.map((el, index) => {
                                                return (
                                                    <div className={'data-row row mt-1 ' + (index === (value.length - 1) ? "data-main-row" : "")}>
                                                        <div
                                                            className={'text-start fw-lighter'}>{index === 0 ? key : ""}</div>
                                                        <div
                                                            className='text-start fw-lighter'>{(typeof el === 'string') ? el : JSON.stringify(el)}</div>
                                                    </div>
                                                )
                                            })
                                            return data;
                                        } else {
                                            return (
                                                <div className='data-row data-main-row row'>
                                                    <div className='text-start fw-lighter'>{key === "timestamp" ? "Date of Processing" : key}</div>
                                                    <div className='text-start fw-lighter'>{key === "timestamp" ? moment(value).format("YYYY-MM-DD HH:mm") : value}</div>
                                                </div>
                                            )
                                        }
                                    }
                                }
                            })}
                        </div>
                    </div>
                }
            </div>
        )
    };

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: false,
        clickToExpand: true,
        selected: selected,
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll
    };

    return (

        <div style={{marginBottom: 100, position: 'relative'}}>
            <PaginationProvider
                pagination={paginationFactory(options)}
            >
                {
                    ({
                         paginationProps,
                         paginationTableProps
                     }) => {
                        return (
                            <div style={{
                                background: "#FFF",
                                borderRadius: "16px 16px 0 0",
                                padding: "0 20px",
                                overflowX: "auto"
                            }}>
                                <BootstrapTable
                                    keyField="pid"
                                    data={props.data}
                                    columns={columns}
                                    selectRow={selectRow}
                                    expandRow={expandRow}
                                    classes="job-table-class"
                                    headerClasses="job-table-header-class"
                                    bodyClasses="job-table-body-class"
                                    {...paginationTableProps}
                                />
                                <div className='text-end pb-3' style={{
                                    marginTop: "-17px",
                                    position: 'absolute',
                                    right: 0,
                                    background: "#FFF",
                                    borderRadius: "0 0 16px 16px",
                                    width: "100%"
                                }}>
                                    <div className='pe-4'>
                                        {paginationProps.page !== 1 &&
                                            <button className="btn normal-font color-secondary"
                                                    onClick={handlePrevPage(paginationProps)}>Prev</button>}
                                        {((paginationProps.totalSize + (SIZE_PER_PAGE - (paginationProps.totalSize % SIZE_PER_PAGE))) / SIZE_PER_PAGE) !== paginationProps.page &&
                                            <button className="btn normal-font color-secondary"
                                                    onClick={handleNextPage(paginationProps)}>Next</button>}

                                    </div>
                                </div>
                            </div>
                        )
                    }
                }
            </PaginationProvider>
        </div>
    )
}

export default JobDataTable;