import * as authActionTypes from '../actionTypes/authActionTypes'

import request from '../../helpers/requestHelper';


export const userLogin=(Data)=> async dispatch=>{
    dispatch({type: authActionTypes.USER_LOGIN_REQUEST});

    let data = {
        email: Data.username,
        password: Data.password
    }

    // let basicAuth = 'Basic ' + btoa(Data.username + ':' + Data.password);

    try{
        const res = await request({
            url:`/signin`,
            auth: false,
            method:'POST',
            data: data
        });

        if(res?.token){
            await localStorage.setItem('token', res.token)
            dispatch({
                type:authActionTypes.USER_LOGIN_SUCCEED,
                payload: res.user
            });
        }else{
            localStorage.removeItem('token')
            dispatch({
                type:authActionTypes.USER_LOGIN_FAILED,
                payload: res ? res : null
            });
        }

        return true;

    } catch (err){
        if(!err.data){
            err = {
                message: "Error Login in - We have an incorrect username or password"
            }
        }
        console.log(err)

        dispatch({
            type:authActionTypes.USER_LOGIN_FAILED,
            payload: err?.data ? err.data : err
        });

        return false
    }
}


export const userLogout=()=>async dispatch=>{
    dispatch({type:authActionTypes.USER_LOGOUT_REQUEST});

    await localStorage.removeItem('token')

    setTimeout(()=>{
        dispatch({type:authActionTypes.USER_LOGOUT_SUCCEED});
        return true;
    },2000)
}
