import * as actionTypes from '../actionTypes/otherActionTypes'

import request from '../../helpers/requestHelper';

export const getAllJobDetails = () => async dispatch => {
    dispatch({type: actionTypes.GET_ALL_JOB_DATA_REQUEST})

    try {
        const res = await request({
            url: `/jobs/`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: actionTypes.GET_ALL_JOB_DATA_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: actionTypes.GET_ALL_JOB_DATA_FAILED,
            payload: err?.data ? err.data : err
        });
    }
}

export const getSingleJobDetails = (id) => async dispatch => {
    dispatch({type: actionTypes.GET_SINGLE_JOB_DATA_REQUEST})

    try {
        const res = await request({
            url: `/jobs/jobinfo?jobid=${id}`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: actionTypes.GET_SINGLE_JOB_DATA_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: actionTypes.GET_SINGLE_JOB_DATA_FAILED,
            payload: err?.data ? err.data : err
        });
    }
}

export const getJobProductDetails = (id) => async dispatch => {
    dispatch({type: actionTypes.GET_JOB_PRODUCT_DATA_REQUEST})

    try {
        const res = await request({
            url: `/products/?pid=${id}`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: actionTypes.GET_JOB_PRODUCT_DATA_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: actionTypes.GET_JOB_PRODUCT_DATA_FAILED,
            payload: err?.data ? err.data : err
        });
    }
}


export const submitJobData = (file) => async dispatch => {
    dispatch({type: actionTypes.SUBMIT_JOB_FILE_REQUEST});

    let data = new FormData();
    data.append('file', file);


    try {
        let res = await request({
            url: `/jobs`,
            auth: true,
            method: 'POST',
            data: data
        });

        res.status = "OK"
        dispatch({
            type: actionTypes.SUBMIT_JOB_FILE_SUCCEED,
            payload: res
        });

        dispatch(getAllJobDetails())
        return res.message;

    } catch (err) {
        dispatch({
            type: actionTypes.SUBMIT_JOB_FILE_FAILED,
            payload: err?.data ? err.data : err
        });

        return false;
    }
}