import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import {useSelector} from "react-redux";

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import JobResult from "./pages/JobResult";

const AppRoutes = () => {

    const isDone = useSelector((el)=> el.allJobData.isDone)

    if(localStorage.getItem('token') && isDone){
        return (
            <BrowserRouter>
                <Routes>
                    <Route exact path='/' element={<Dashboard/>}/>
                    <Route exact path='/job/:jobId' element={<JobResult/>}/>
                    <Route path="*" element={<Navigate replace to="/"/>}/>
                </Routes>
            </BrowserRouter>
        )
    } else if(!localStorage.getItem('token')){
        return (
            <BrowserRouter>
                <Routes>
                    <Route path='/login' element={<Login/>}/>
                    <Route path="*" element={<Navigate replace to="/login"/>}/>
                </Routes>
            </BrowserRouter>
        )
    } else {
        return null
    }

}

export default AppRoutes;