import React from 'react';
import { Spinner } from 'reactstrap';


const OverlaySpinner=(props)=>{

	const styles = {
		full:{
			backgroundColor:'rgba(255,255,255,0.98)', 
			width:'100%', 
			height:'100%', 
			position:'fixed', 
			zIndex:'1000',
		},
		fullSpinner:{
			position:'fixed', 
			top:'45%', 
			left:'50%',
			marginLeft:'-17px',
			zIndex:'1000'
		},
	}

	if(props.isLoading){
		return(
			<div style={styles.full}>
				<div style={styles.fullSpinner}>
					<Spinner style={{color:"#79BEF1"}}  animation='border'>
					{' '}						
					</Spinner>	
		        </div>	
	        </div>	
		)
	}else{
		return null
	}
}

export default OverlaySpinner;