import {useDispatch, useSelector} from "react-redux";
import OverlaySpinner from "./components/OverlaySpinner";
import AppRoutes from "./Routes";
import {useEffect, useState} from "react";
import {getAllJobDetails} from "./redux/actions/otherActions";
import {Alert} from "react-bootstrap";

function App() {

    const dispatch = useDispatch();

    const isLoading = useSelector((el)=> el.isLoading)
    const errorData = useSelector((el) => el.error)

    const [alert, setAlert] = useState('')

    useEffect(()=>{
        if(localStorage.getItem('token'))
            dispatch(getAllJobDetails());
    }, [localStorage.getItem('token')])

    useEffect(() => {
        const interval = setInterval(() => {
            if(localStorage.getItem('token'))
                dispatch(getAllJobDetails());
        }, 10000);
        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        if (errorData && (errorData.code === 404 || errorData.status === "KO" || errorData.message) ) {
            setAlert(errorData?.message)
            setTimeout(()=>{
                setAlert('')
            }, 5000)
        }
    }, [errorData])

    return (
        <div>
            <OverlaySpinner isLoading={isLoading} />
            {alert && <Alert style={{position:'fixed', height:70, width:'100%', top:0, left:0, zIndex:1000, borderRadius:0}} variant={'danger'} className='normal-font d-flex align-items-center justify-content-center'>{alert}</Alert> }
            <AppRoutes/>
        </div>
    );
}

export default App;
