import React from "react";
import {Dropdown} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {userLogout} from "../redux/actions/authActions";
import {Link, useNavigate} from "react-router-dom";


const Header=(props)=>{

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onLogoutClick=()=>{
        dispatch(userLogout());
        navigate('/login')
    }

    return(
        <div className='header-container'>
            <div className='container container-new d-flex align-items-center'>
                <Link to={'/'} style={{width:'25%', textDecoration:'none', color:'#FFF'}}>CommerceFlow.ai</Link>
                <div style={{width:'50%'}} className='header-topic text-center'>{props.header}</div>
                <div style={{width:'25%'}} className='text-end'>
                    <Dropdown className='dropdown-user-options'>
                        <Dropdown.Toggle id="dropdown-basic">
                            <div className="avatar-lg rounded-circle mb-0 mt-2" style={{maxHeight:'100%', background:"#FFF"}}>
                                <img
                                    src={`/images/avatar.png`}
                                    alt=""
                                    className="rounded-circle profile-img"
                                    style={{maxWidth:'100%', maxHeight:'50px'}}
                                />
                            </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu align={'end'}>
                            <Dropdown.Item className='normal-font text-center' >Change Password</Dropdown.Item>
                            <Dropdown.Item className='normal-font text-center' onClick={onLogoutClick}>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </div>
    )
}

export default Header;