import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import Header from "../components/Header";
import DetailItem from "../components/DetailItem";
import FileUpload from "../components/FileUpload";

import {ReactComponent as Case} from '../assets/case.svg'
import {ReactComponent as Circle} from '../assets/circle.svg'
import {ReactComponent as Tick} from '../assets/tick.svg'
import JobTable from "../components/JobTable";
import {submitJobData} from "../redux/actions/otherActions";
import {Alert} from "react-bootstrap";

const Dashboard = () => {

    const dispatch = useDispatch();
    const jobDetails = useSelector((el)=> el.allJobData)

    const [fileState, setFileState] = useState(null)
    const [alert, setAlert] = useState('')

    const onSubmitClick=()=>{
        dispatch(submitJobData(fileState)).then((res)=>{
            if(res){
                setAlert(res)
                setTimeout(()=>{
                    setAlert('')
                },8000)
                setFileState(null)
            }
        })
    }

    return (
        <div className='mb-4'>
            {alert && <Alert style={{position:'fixed', height:70, width:'100%', top:0, left:0, zIndex:1000, textAlign:'right', borderRadius:0}} variant={'success'} className='normal-font d-flex align-items-center justify-content-center'>{alert}</Alert> }
            <Header header={"Dashboard"}/>
            <div className='container container-new'>
                <div className='d-block d-md-flex justify-content-between mt-4'>
                    <DetailItem
                        icon={<Case/>}
                        number={jobDetails.number_of_jobs}
                        details={"No. of Jobs"}
                    />
                    <DetailItem
                        icon={<Tick/>}
                        number={jobDetails.jobs_complete}
                        details={"Job Complete"}
                    />
                    <DetailItem
                        icon={<Circle/>}
                        number={jobDetails.jobs_in_progress}
                        details={"In Progress"}
                    />
                </div>

                <div className='mt-4'>
                    <div>New Job</div>
                    <div className='round-container pt-4 pb-4 row m-0 mt-2'>
                        <div className='col-6'>
                            <FileUpload fileState={fileState} setFileState={setFileState}/>
                        </div>
                        <div className='col-6'>
                            <div style={{maxWidth: 150}}>
                                <button onClick={onSubmitClick} disabled={!fileState} className="button-custom btn">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mt-4'>
                    <div>Jobs</div>
                    <div className='mt-2'>
                        <JobTable
                            data={jobDetails.data}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;