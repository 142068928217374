const DetailItem=(props)=>{

    return(
        <div className='detail-item-container mx-auto mx-md-0 row mt-2'>
            <div className='col-4'>
                <div className='icon-container d-flex align-items-center justify-content-center'>
                    {props.icon}
                </div>
            </div>

            <div className='detail-container col-8'>
                <div>{props.number}</div>
                <div>{props.details}</div>
            </div>
        </div>
    )
}

export default DetailItem;