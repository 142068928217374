import {useEffect, useRef, useState} from "react";
import {FileDrop} from "react-file-drop";

import { ReactComponent as Files } from '../assets/files.svg'

const FileUpload=(props)=>{

    const fileInputRef = useRef(null);

    useEffect(()=>{
        if(!props.fileState && fileInputRef?.current){
            // fileInputRef.current.files = new FileList();
        }
    }, [props.fileState])

    const onFileInputChange = (event) => {
        const { files } = event.target;
        props.setFileState(files[0])
    }

    const onTargetClick = () => {
        fileInputRef.current.click()
    }

    const onFileDrop=(files) => {
        for (let i = 0; i < files.length; i++) {
            let exe = files[i].name.split('.').pop()
            if(exe === 'csv' || exe === 'tsv' || exe === 'xlsx' || exe === 'xls'){
                props.setFileState(files[i]);
                break;
            }
        }
    }

    return(
        <div className='mt-0 ms-auto' style={{maxWidth:'160px'}}>
            <div>
                <input
                    type='file'
                    name='file'
                    id='file'
                    accept='.csv, .tsv, .xlsx, .xls'
                    ref={fileInputRef}
                    onChange={onFileInputChange}
                    style={{display:'none'}}
                />
                {props.fileState ?
                    <FileDrop
                        onTargetClick={onTargetClick}
                        onDrop={onFileDrop}
                    >
                        <div style={{width:'100%'}}>
                            <div className='file-name'>{props.fileState.name}</div>
                        </div>
                    </FileDrop>
                    :
                    <FileDrop
                        onTargetClick={onTargetClick}
                        onDrop={onFileDrop}
                    >
                        <div>
                            <div className='text-center'><Files height={50}/></div>
                        </div>
                    </FileDrop>
                }
            </div>
        </div>
    )
}

export default FileUpload;